import React from "react";
import PropTypes from "prop-types";
import Spinner from "../Spinner";

const Button = ({
  className,
  type,
  disabled,
  onClick,
  Icon,
  tooltipId,
  tooltipContent
}) => {
  return (
    <button
      type={type}
      className={className}
      disabled={disabled}
      onClick={onClick}
      title={tooltipContent} // Title is set for the button itself
      data-tooltip-id={tooltipId} // Tooltip ID passed as a prop
      data-tooltip-content={tooltipContent} // Tooltip content passed as a prop
    >
      <div className="upload-btn">
        {disabled && <Spinner />}
        {Icon && !disabled && <Icon size={24} />} {/* Render the passed icon */}
      </div>
    </button>
  );
};

// PropTypes for validation
Button.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  Icon: PropTypes.elementType, // Prop to pass the icon component
  tooltipId: PropTypes.string, // Tooltip ID
  tooltipContent: PropTypes.string, // Tooltip content
};

Button.defaultProps = {
  className: "",
  type: "button",
  disabled: false,
  Icon: null, // Default to no icon
  tooltipId: "tooltip", // Default tooltip ID
  tooltipContent: "", // Default empty content
};

export default Button;
