import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../auth/Context/AuthContext";
import "./PrivateRoute.css";
import { ROUTES } from "../../constant/routes";

function Index() {
  const { isAuthenticated, loading } = useAuth();

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loader"></div>
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to={ROUTES.LOGIN} />;
  }

  return <Outlet />;
}

export default Index;
