import React from "react";
import { Header } from "./Layout";

const Index = ({ theme }) => {
  return (
      <Header theme={theme} />
  );
};

export default Index;
