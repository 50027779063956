import React, { useEffect, useState } from "react";
import {  useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import "./Login.css";
import "../../App.css";
import { PRIVATE_ROUTES } from "../../constant/routes";
import { EMAIL, LOGIN, PASSWORD } from "../../constant";
import { MainTitle } from "../../components/Common";
import { useAuth } from "../Context/AuthContext";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const auth = getAuth();
  const { isAuthenticated } = useAuth();

  useEffect(()=> {

    // Redirect to the main page if the user is logged in and tries to access the login page
    if(isAuthenticated) {
      navigate(PRIVATE_ROUTES.INDEX);
    }

  },[isAuthenticated,navigate])

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent the form from refreshing the page

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      console.log("User signed in:", userCredential);
      if (userCredential) {
        // Redirect to your dashboard or main app
        navigate(PRIVATE_ROUTES.INDEX);
      }
    } catch (err) {
      console.error("Error signing in:", err);
      setError(err.message);
    }
  };

  return (
    <div className="login-container">
      <MainTitle title={LOGIN} />
      <form onSubmit={handleLogin}>
        <div className="input-group">
          <label>{EMAIL}</label>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="input-group">
          <label>{PASSWORD}</label>
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit">{LOGIN}</button>
        {error && <p>{error}</p>}
      </form>
    </div>
  );
};

export default Login;
