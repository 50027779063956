import React, { useEffect, useRef, useState, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import "@finos/perspective-viewer";
import "@finos/perspective-viewer-datagrid";
import "@finos/perspective-viewer-d3fc";
import "@finos/perspective-viewer/dist/css/themes.css";
import perspective from "@finos/perspective";
import moment from "moment-timezone";
import Papa from "papaparse";
import { ThemeContext } from "../Common/Theme/Context";
import "./ReportViewer.css";
import { ERROR, NO_DATA_AVAILABLE, NO_DATA_FOUND, TIME_ZONE } from "../../constant";


const ReportViewer = ({ csvUrl, fromDate, toDate, config, field }) => {
  let content;
  
  const viewerRef = useRef(null);
  const { theme } = useContext(ThemeContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [noData, setNoData] = useState(false);

  useEffect(() => {
    const fetchCsvData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(csvUrl);
        if (!response.ok) {
          throw new Error(
            `Network response was not ok: ${response.statusText}`
          );
        }
        const csvText = await response.text();
        console.log('Date',new Date())

        // Parse CSV data
        const parsedData = Papa.parse(csvText, { header: true });
        if (parsedData.errors.length > 0) {
          throw new Error(`CSV Parsing Error: ${parsedData.errors[0].message}`);
        }

        // If necessary, reformat the time_utc field
        const formattedData = parsedData.data.map((row) => {
          const dateStr = row.date_local;
          if (dateStr) {
            row.time_utc_number = convertToCST(row['time_local']);
            row['Time (CST)'] = convertToUTC(row['time_local']);
          }
          return row;
        });

        setData(formattedData);
      } catch (error) {
        console.error("Failed to fetch CSV data:", error.message);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCsvData();
  }, [csvUrl, field]);

  const getPastDate = useMemo(()=>{
    if(toDate) {
      let date = new Date(toDate);
      date.setDate(date.getDate() - 1);
      return date.toISOString().split('T')[0];
    }
    return null;
  },[toDate])

  const convertToCDT = (utcTimeString, format="") => {
    const unixTimestamp = moment(utcTimeString).tz('America/Chicago').format(format);
    return unixTimestamp;
  };

  const convertToUTC = (utcTimeString) => {
    const unixTimestamp = moment(utcTimeString).tz('America/Chicago').format();
    return unixTimestamp;
  };
  const convertToCST = (utcTimeString) => {
    const unixTimestamp = moment(utcTimeString).tz('America/Chicago').unix();
    return unixTimestamp;
  };

  const convert = (dateString, format,type) => {
    console.log('Type',type,moment(dateString+format).tz('America/Chicago').format())
    return moment(dateString+format).tz('America/Chicago').unix()
  }
  
  useEffect(() => {
    const loadPerspective = async () => {
      if (!viewerRef.current || data.length === 0) {
        setNoData(true);
      }
      setNoData(false);

      try {
        const from = fromDate ? moment(fromDate).unix() : null;
        console.log('FromUnix',from)
        const to = toDate ? moment(toDate).unix() : null;
        const futureDate = to ?  moment(to*1000).format('YYYY-MM-DD') : null;
        const pastDateOnly = to ? moment(to*1000).subtract(1,'days').format('YYYY-MM-DD') : null;
        const fromDateOnly = fromDate ? moment(fromDate).format('YYYY-MM-DD') : null;
        console.log('fromDate: ' + fromDateOnly)
        console.log('pastDateOnly: ' + pastDateOnly)
        console.log('futureDateOnly: ' + futureDate)

        // if(from === to && from !== null && to !== null){
        //   setError('Same date is not allowed')

        //   if (viewerRef.current) {
        //     viewerRef.current.delete();
        //   }
        //   return;
        // }
       

        const filteredData = data.filter((row,i) => {
          const value = row[field] ;
          if (!value) return false;
          const dateValue = row['time_utc_number']
          const time_cst_date =  row['time_cst_date'];
          if(i ===0){
            console.log('timecst',row['time_cst_date'],dateValue)
          }
          
          if(from === null && to === null) {
            return true
          }
          else if(from === to && from !== null && to !== null){
            if(dateValue < fromDate){
              return true;
            }
            else{
              return false;
            }

          } else if (from && parseFloat(dateValue) >= parseFloat(from) && to && parseFloat(dateValue) < parseFloat(to)   ) { 
            return !(futureDate == row['time_cst_date']);
            // return true
          
            
          } else if (to && dateValue <= to && from == null) {
            // return !(futureDate == row['time_utc'].split('T')[0] && futureDate !== fromDate);
         
            return true;
          } else if (from && dateValue >= from && to == null) {
            // return !(futureDate == row['time_utc'].split('T')[0]);
            
            return true;
          } else if(from && to && time_cst_date == fromDateOnly &&  futureDate == fromDateOnly){
            return true;
          } else {
            return false;
          }
        });

        console.log('filter',filteredData)
        if (filteredData.length === 0) {
          setNoData(true);
          if (viewerRef.current) {
            viewerRef.current.delete();
          }
          return;
        }

        const schema = {
          "Time (CST)": "datetime",  // UTC time in ISO format
          "time_local": "string", // Local time as "string" (may need custom formatting for Perspective)
          "date_local": "string", // Local date "string"
          "BESS MW": "float",  // BESS MW (numeric value)
          "BESS MWh": "float", // BESS MWh (numeric value)
          "RTLMP": "float",      // Real-Time LMP (numeric value)
          "RTM Revenue": "float",  // RTM Revenue (numeric value)
          "RTM Cost": "float",     // RTM Cost (numeric value)
          "DAM AWARD MWh": "float", // DAM Award MWh (numeric value)
          "DALMP": "float",      // DALMP (numeric value)
          "DALMP Revenue": "float", // DALMP Revenue (numeric value)
          "DALMP Settlement": "float", // DALMP Settlement (numeric value)
          "ECRS AWARD MW": "float",   // ECRS AWARD MW (numeric value)
          "ECRS Price": "float",      // ECRS Price (numeric value)
          "ECRS Revenue": "float",    // ECRS Revenue (numeric value)
          "RRS AWARD MW": "float",   // RRS AWARD MW (numeric value)
          "RRS Price": "float",      // RRS Price (numeric value)
          "RRS Revenue": "float",    // RRS Revenue (numeric value)
        }

        const worker = perspective.worker();
        const table = await worker.table(schema);
        table.update(filteredData)

        const perspectiveTheme = theme === "light" ? "Pro Light" : "Pro Dark";

        viewerRef.current.load(table);
        viewerRef.current.restore({
          ...config,
          theme: perspectiveTheme,
          timezone: TIME_ZONE, 
          columns: config.columns || Object.keys(filteredData[0] || {}),
        });

        viewerRef.current.setAttribute('timezone', TIME_ZONE);
      } catch (error) {
        console.error("Failed to load data into Perspective:", error.message);
        setError(error.message);
      }
    };

    loadPerspective();
  }, [data, fromDate, toDate, theme, config, getPastDate, field]);

  if (loading) {
    content = <div className="loader"></div>;
  } else if (error) {
    content = <div className="error-message">{ERROR}: {error}</div>;
  } else if (noData && !loading && !error && data?.length !== 0) {
    content = <div className="message">{NO_DATA_AVAILABLE}</div>;
  } else if (data?.length === 0) {
    content = <div className="no-data-message">{NO_DATA_FOUND}</div>;
  } else {
    content = <perspective-viewer ref={viewerRef}></perspective-viewer>;
  }

  return (
    <div className={`report-viewer-container ${theme}-theme`}>
      <div
        className={`perspective-container ${
          data?.length === 0 || error || (noData && !loading && !error)
            ? "perspective-no-data"
            : ""
        }`}
      >
        {content}
      </div>
    </div>
  );
};

// Add PropTypes to validate the props
ReportViewer.propTypes = {
  csvUrl: PropTypes.string.isRequired,
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
  config: PropTypes.object,
  field: PropTypes.string,
};

export default ReportViewer;
