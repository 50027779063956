import React, { useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { FaDownload, FaFileUpload } from "react-icons/fa";
import { BiReset } from "react-icons/bi";
import { useFormik } from "formik";
import Papa from "papaparse";
import "./ReportsUpload.css";
import { Button, FormikError, MainTitle } from "../../Common";
import {
  CSV_IMPORT_TABLE,
  DOWNLOAD_SAMPLE_CSV,
  IS_SELECT_CSV,
  REPORTS_CSV_UPLOAD,
  RESET,
  SAMPLE_CSV_DATA,
  SAMPLE_CSV_HEADERS,
  UPLOAD,
  UPLOAD_MESSAGE_CSV,
} from "../../../constant";
import {
  acceptStyle,
  baseStyle,
  focusedStyle,
  rejectStyle,
} from "../../Common/Styles";
import { BACKEND_API } from "../../../config";

const ReportsUpload = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);

  const generateCSVContent = () => {
    const headers = SAMPLE_CSV_HEADERS;
    const sampleData = [SAMPLE_CSV_DATA];

    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += headers.join(",") + "\n";
    sampleData.forEach((row) => {
      csvContent += row.join(",") + "\n";
    });

    return encodeURI(csvContent);
  };

  const downloadCSV = () => {
    const csvContent = generateCSVContent();
    const link = document.createElement("a");
    link.setAttribute("href", csvContent);
    link.setAttribute("download", "sample.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      multiple: false,
      accept: { "text/csv": [".csv"] },
      onDrop: (acceptedFiles) => {
        if (
          acceptedFiles &&
          acceptedFiles[0] &&
          acceptedFiles[0].type === "text/csv"
        ) {
          const file = acceptedFiles[0];
          if (file) {
            const trimRowCells = (row) => row.map((cell) => cell.trim());

            Papa.parse(file, {
              complete: (result) => {
                if (result.data) {
                  const trimmedData = result.data.map(trimRowCells);
                  setCsvData(trimmedData);
                }
              },
              header: false,
            });
            formik.setFieldValue("csvFile", file); // Set the selected CSV file in formik values
          }
        } else {
          setCsvData([]);
          formik.setFieldValue("csvFile", null);
          formik.setFieldError("csvFile", IS_SELECT_CSV);
        }
      },
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      flexDirection: undefined,
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const formik = useFormik({
    initialValues: {
      csvFile: null,
    },
    validate: (values) => {
      const errors = {};
      if (!values.csvFile) {
        errors.csvFile = "Please select a valid CSV file to upload.";
      }
      return errors;
    },
    onSubmit: async (values) => {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("file", values.csvFile);
  
      try {
        const requestOptions = {
          method: "POST",
          body: formData,
          redirect: "follow",
        };
  
        console.log("Initiating file upload...");
  
        const response = await fetch(`${BACKEND_API}revenue/report/upload`, requestOptions);
        const data = await response.json();
        console.log('response',data)
        if (!response.ok) {
          throw new Error(data.message);
        }
  
       
  
        if (data.code === 200) {
          console.log("File uploaded successfully:", data);
          setCsvData([]);
          formik.resetForm();
          toast.dismiss();
          toast.success(data.message);
        } else {
          console.warn("Unexpected response:", data);
          throw new Error(data.message || "Unknown error occurred. Please try again.");
        }
        
      } catch (error) {
        console.error("Error during file upload:", error);
  
        let errorMessage = "Something went wrong. Please try again.";
        if (error.message) {
          errorMessage = error.message;
        } else if (error.response?.data?.message) {
          errorMessage = error.response.data.message;
        }
      
        toast.dismiss();
        toast.error(errorMessage);
      } finally {
        console.log("File upload process finished.");
        setIsLoading(false);
      }
    },
  });
  

  return (
    <div className="report-upload-container">
      <MainTitle title={REPORTS_CSV_UPLOAD} />
      <form
        className="report-upload-form-container"
        onSubmit={formik.handleSubmit}
      >
        <div className="report-upload-form">
          <div className="report-upload-csv-btn">
            <Button
              className="report-upload-icon"
              label={DOWNLOAD_SAMPLE_CSV}
              onClick={downloadCSV}
              Icon={FaDownload}
              tooltipId="tooltip"
              tooltipContent={DOWNLOAD_SAMPLE_CSV}
            />
            <Button
              type="submit"
              className="report-upload-icon"
              label={UPLOAD}
              disabled={isLoading}
              Icon={FaFileUpload}
              tooltipId="tooltip"
              tooltipContent={UPLOAD}
            />
            {csvData?.length > 0 && (
              <Button
                className="report-upload-icon"
                label={RESET}
                onClick={() => {
                  setCsvData([]);
                  formik.setFieldValue("csvFile", null);
                }}
                Icon={BiReset}
                tooltipId="tooltip"
                tooltipContent={RESET}
              />
            )}
          </div>
          <div>
            <div className="report-upload-drag-container">
              <section>
                <div {...getRootProps({ style })}>
                  <input {...getInputProps()} />
                  <p className="report-upload-message">{UPLOAD_MESSAGE_CSV}</p>
                </div>
                {formik.touched.csvFile && formik.errors.csvFile && (
                  <FormikError label={formik.errors.csvFile} />
                )}
              </section>
            </div>
          </div>

          <div>
            {csvData?.length > 0 && (
              <div>
                <h2>{CSV_IMPORT_TABLE}</h2>
                <div className="table-container">
                  <div className="table-lines-container">
                    <table>
                      <thead>
                        <tr>
                          {csvData[0]?.map((header, index) => (
                            <th
                              className="TableIndexNumber"
                              key={`${header}-${index}`}
                            >
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {csvData?.slice(1).map(
                          (row, rowIndex) =>
                            row.some((cell) => cell.trim() !== "") && (
                              <tr key={`${row}-${rowIndex}`}>
                                {row.map((cell, cellIndex) => (
                                  <td
                                    className="TableIndexNumber"
                                    key={`cell-${rowIndex}-${cellIndex}`}
                                  >
                                    {cell}
                                  </td>
                                ))}
                              </tr>
                            )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default ReportsUpload;
