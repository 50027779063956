const firebaseConfig = {
    apiKey: "AIzaSyAsFMozp-FZitZvfgtbg6Bf8mdDomy0fnU",
    authDomain: "fractal-optimizer-b7287.firebaseapp.com",
    projectId: "fractal-optimizer-b7287",
    storageBucket: "fractal-optimizer-b7287.appspot.com",
    messagingSenderId: "555817109765",
    appId: "1:555817109765:web:81a7af626b7f8edfea8f6c",
    measurementId: "G-WT07CXFECZ"
  };

  export default firebaseConfig;