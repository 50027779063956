export const ROUTES = {
  LOGIN: "/login",
};

export const PRIVATE_ROUTES = {
  PAGENOTFOUND: "/*",
  INDEX: "/",
  REPORT: "/report",
  REPORT_UPLOAD: "/report/upload",
};
