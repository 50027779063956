import React, { useContext } from 'react';
import './App.css'; // Make sure to import the CSS file
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { MarketDataViewer, EMSDataViewer } from './components/Home';
import { Report, ReportUpload } from './components/Reports';
import { ThemeContext } from './components/Common/Theme/Context';

import Login from './auth/Login';
import { PrivateRoute } from './routes';
import { APP_TITLE } from './constant';
import { PRIVATE_ROUTES, ROUTES } from './constant/routes';
import { Layouts } from './components/Common';


function App() {
  const { theme } = useContext(ThemeContext);


  return (
    <Router basename={PRIVATE_ROUTES.INDEX}>
      <div className={`App ${theme}-theme`}>
        <Helmet>
          <title>{APP_TITLE}</title>
        </Helmet>

        <Layouts theme={theme} />
         
        <Routes>
          <Route path={ROUTES.LOGIN} element={<Login />} />
            <Route element={<PrivateRoute />}>
              <Route
                path={PRIVATE_ROUTES.INDEX}
                element={
                  <div className="chart-grid">
                    <EMSDataViewer />
                    <MarketDataViewer />
                  </div>
                }
              />
              <Route
                path={PRIVATE_ROUTES.REPORT}
                element={<Report />}
              />
              <Route
                path={PRIVATE_ROUTES.REPORT_UPLOAD}
                element={<ReportUpload />}
              />
            </Route>
          </Routes>
      </div>
    </Router>
  );
}

export default App;